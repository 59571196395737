export const AirlinesJson = [
  {
    label: "Blue Air",
    value: "0B",
  },
  {
    label: "Ak Bars Aero",
    value: "2B",
  },
  {
    label: "SNCF",
    value: "2C",
  },
  {
    label: "Star Peru",
    value: "2I",
  },
  {
    label: "Air Burkina",
    value: "2J",
  },
  {
    label: "Avianca Ecuador",
    value: "2K",
  },
  {
    label: "Helvetic Airways",
    value: "2L",
  },
];
